import { canNavigate } from '@/plugins/acl/routeProtection'
import Vue from 'vue'
import VueRouter from 'vue-router'
import postkartenTool from './postkartenTool'
import chartsAndMaps from './user-interface/charts-and-maps'
import axios from "@axios";
import VueToastr from 'vue-toastr';
import { initialAbility } from '@/plugins/acl/config.js'
import ability, { updateAbility } from '@/plugins/acl/ability.js'

Vue.use(VueRouter)
Vue.use(VueToastr)
const tmpVue = new Vue()

const routes = [
  {
    path: '/',
    redirect: to => {
      const userData = JSON.parse(localStorage.getItem('userData'))
      if(userData !== null && Object.keys(userData).length > 0) return { name: 'dashboard' }
      return { name: 'auth-login', query: to.query }
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/Register.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  ...postkartenTool,
  ...chartsAndMaps,
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
});

const setCookie = (cname, cvalue, exdays, roundUp) => {
  var domain = getDomain(window.location.host);
  var d = new Date();
  var d = new Date();
  var stunden = roundUp ? (24 - d.getHours()) * 60 * 60 * 1000 : 0;
  var minuten = roundUp ? (60 - d.getMinutes()) * 60 * 1000 : 0;
  var sekunden = roundUp ? (60 - d.getSeconds()) * 1000 : 0;
  d.setTime(d.getTime() + ((exdays) * 24 * 60 * 60 * 1000) + stunden + minuten + sekunden);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + "; " + expires + ";domain=." + domain + ";path=/;";
}

const getURLParameter = (name) => {
  return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search) || [null, ''])[1].replace(/\+/g, '%20')) || null;
}

const getDomain = (url, subdomain) => {
  subdomain = subdomain || false;
  url = url.replace(/(https?:\/\/)?(www.)?/i, '');
  if (!subdomain) {
    url = url.split('.');
    url = url.slice(url.length - 2).join('.');
  }
  if (url.indexOf('/') !== -1) {
    return url.split('/')[0];
  }

  url = url.replace(":8080","");

  return url;
}

const checkLogin = async (to) => {
  if (to.meta.resource !== "Public") {
    try {
      const res = await axios.post('/api/login/', { aktion: 'checkUserLogin' });
      const { user, loginState } = res.data;
      const { ability: userAbility } = user;

      localStorage.setItem('userAbility', JSON.stringify(userAbility));
      delete user.ability;
      localStorage.setItem('userData', JSON.stringify(user));

      updateAbility(userAbility);

      if (!loginState) {
        tmpVue.$toastr.e('Ihre Loginsitzung ist abgelaufen', 'Sitzung abgelaufen');
        return router.push('/login');
      }
    } catch (error) {
      localStorage.setItem('userData', JSON.stringify({}));
      localStorage.setItem('userAbility', JSON.stringify(initialAbility));
      updateAbility(initialAbility);
      tmpVue.$toastr.e('Ihre Loginsitzung ist abgelaufen', 'Sitzung abgelaufen');
      return router.push('/login');
    }
  }
};

const checkUserAccess = () => {
  const userData = JSON.parse(localStorage.getItem('userData'));
  const userAbility = JSON.parse(localStorage.getItem('userAbility'));
  return userData && Object.keys(userData).length > 0 && userAbility && Object.keys(userAbility).length > 0;
};

router.beforeEach(async (to, from, next) => {

  await checkLogin(to);
  const isLoggedIn = checkUserAccess();

  if (!canNavigate(to)) {
    if (to.name === 'misc-not-authorized') {
      return next();
    }

    if (!isLoggedIn) {
      return next({ name: 'auth-login', query: { marketplace: to.query.marketplace } });
    }

    return next({ name: 'misc-not-authorized' });
  }

  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    return next('/');
  }

  return next();
});

export default router
