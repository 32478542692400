import { Ability } from '@casl/ability'
import { initialAbility } from './config'

const existingAbility = JSON.parse(localStorage.getItem('userAbility')) || initialAbility

// Erstelle eine Ability-Instanz
const ability = new Ability(existingAbility)

// Funktion zum Aktualisieren der Ability
export const updateAbility = (newAbilities) => {
  ability.update(newAbilities) // CASL-Funktion zum Aktualisieren der Rechte
}

// Standard-Export der Ability-Instanz
export default ability
