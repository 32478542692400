import {ref} from "@vue/composition-api";

export default function useHelper(){

  const resolveUserStatusVariant = status => {
    if (status == 0) return 'secondary'
    if (status == 1) return 'success'

    return 'primary'
  }
  const resolveUserStatusText = status => {
    if (status == 0) return 'Nein'
    if (status == 1) return 'Ja'

    return ''
  }

  const downloadCSVData = (csvString,fileName) => {
    let filename = fileName || (Date.now()+'_download.csv');
    const anchor = document.createElement('a');
    anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString);
    anchor.target = '_blank';
    anchor.download = filename;
    anchor.click();
  }

  const genders = [
    {label: 'Männlich', value: '1'},
    {label: 'Weiblich', value: '2'},
    {label: 'Divers', value: '0'},
  ];
  const purchasingPowers = [
    {label: 'Einkommen < 1.100 Euro', value: '70401100'},
    {label: 'Einkommen 1.100 - 2.600 Euro', value: '70401200|70401300|70401400'},
    {label: 'Einkommen 2.600 - 4.000 Euro', value: '70401500'},
    {label: 'Einkommen 4.000 - 7.500 Euro', value: '70401600'},
    {label: 'Einkommen >7.500 Euro ', value: '70401700'},
  ];

  const houseTypes = [
    {label: '1-2 Familienhaus', value: '70102100|70102200'},
    {label: '3-10 Familienhaus', value: '70102300|70102400|70102500'},
    {label: '11-50 Familienhaus', value: '70102600|70102700'},
    {label: '>51 Familienhaus', value: '70102800'},
  ];

  const campaignStates = [
    {value: "all"                     , text: "Alle"                          , color: "info"       , description : ""},
    {value: "inBearbeitung"           , text: "in Bearbeitung"                , color: "secondary"  , description : "Ihre Kampagne befindet sich aktuell in der Bearbeitungsphase. Sie können die Inhalte noch nach Belieben anpassen oder die Kampagne löschen, falls erforderlich."},
    {value: "inInternerFreigabe"      , text: "Freigabe ausstehend"           , color: "warning"    , description : "Ihre Kampagne wurde zur internen Freigabe bereitgestellt. Dritte können über einen bereitgestellten Link eine Vorschau der Kampagne sehen und gegebenenfalls Korrekturen vorschlagen."},
    {value: "inBearbeitungKorrektur"  , text: "Korrektur erwünscht"           , color: "accent"     , description : "Ihre Kampagne hat Korrekturvorschläge erhalten. Sie können diese Änderungen vornehmen oder die Korrekturen als bestätigt markieren, um zur internen Freigabe zurückzukehren."},
    {value: "inBezahlung"             , text: "Freigegeben"                   , color: "success"    , description : "Ihre Kampagne hat den Freigabeprozess durchlaufen und ist bereit zur Bezahlung. Bitte führen Sie den Bezahlvorgang durch, um den Prozess fortzusetzen."},
    {value: "inExternerFreigabe"      , text: "in Kontrolle durch Mailvista"  , color: "warning"    , description : "Ihre Kampagne wurde erfolgreich bezahlt und liegt nun beim Webseitenbetreiber (Mailvista) zur externen Freigabe vor. Ein Mitarbeiter von Mailvista wird die Kampagne prüfen."},
    {value: "inAnreicherung"          , text: "Versand Vorbereitung"          , color: "info"       , description : "Die Zielgruppenempfänger für Ihre Kampagne werden derzeit durch einen internen Prozess von Mailvista aufbereitet und angereichert."},
    {value: "inVersandstellung"       , text: "Versandbereit"                 , color: "info"       , description : "Ihre Kampagne ist bereit für den Versand. Alles ist eingerichtet, und es wird nur noch auf den von Ihnen eingestellten Versandzeitpunkt gewartet, damit die Kampagne als Postkarte versendet werden kann."},
    {value: "inLettershop"            , text: "Versendet"                     , color: "success"    , description : "Ihre Kampagne wurde erfolgreich versendet."},
    {value: "inVersendet"             , text: "Versendet"                     , color: "success"    , description : "Ihre Kampagne wurde erfolgreich versendet."},
    {value: "inVersandFehlgeschlagen" , text: "Versand fehlgeschlagen"        , color: "error"      , description : "Beim Versuch, Ihre Kampagne zu versenden, ist ein Fehler aufgetreten. Bitte überprüfen Sie die Kampagne und versuchen Sie es erneut."},
    {value: "inStorniert"             , text: "Storniert"                     , color: "error"      , description : "Ihre Kampagne wurde vom Kampagnenersteller storniert."},
  ];


  const getCampaignStateText = (state) => {
    let text = "";
    campaignStates.forEach(item => {
      if(item.value == state){
        text = item.text
      }
    })
    return text;
  }

  const getCampaignStateColor = (state) => {
    let color = "";
    campaignStates.forEach(item => {
      if(item.value == state){
        color = item.color
      }
    })
    return color;
  }

  const getCampaignStateDescription = (state) => {
    let description = "";
    campaignStates.forEach(item => {
      if(item.value == state){
        description = item.description
      }
    })
    return description;
  }

  const getPaymentColor = (status) =>{
    let color = '';
    switch(status){
      case "offen" :
        color = 'secondary';
        break;
      case "bezahlt" :
        color = 'success';
        break;
      case "ausstehend" :
        color = 'secondary';
        break;
      case "fehlgeschlagen" :
        color = 'error';
        break;
      case "abgelaufen" :
        color = 'warning';
        break;
      case "abgebrochen" :
        color = 'error';
        break;
      case "erstattet" :
        color = 'info';
        break;
      case "rückbuchung" :
        color = 'info';
        break;
    }
    return color;
  }

  const getMatchedItem = (list,key,value) => {

    let matchedItem = [];

    list.forEach((item) => {
      if(item[key] == value){
        matchedItem = item;
      }
    })

    return matchedItem
  }

  const convertToGermanFormat = (numberStr) => {
    try {
      // Remove commas (thousand separators in English format)
      let cleanStr = numberStr.replace(/,/g, '');

      // Parse the number
      let number = parseFloat(cleanStr);

      // Check if the conversion is valid
      if (isNaN(number)) {
        throw new Error("Invalid number format");
      }

      // Convert the number to German format with two decimal places
      return number.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    } catch (error) {
      console.error("Error converting number:", error.message);
      return null; // or you could return the original input, or any other fallback value
    }
  }

  const chartColors = ['#00d4bd','#826bf8','#2b9bf4','#58339e','#e8901c','#ffe700',];


  const donutTemplate = {
    series: [85, 16, 50, 50,4],
    chartOptions: {
      labels: ['Operational', 'Networking', 'Hiring', 'R&D','Ramazan'],
      legend: {
        show: true,
        position: 'bottom',
        fontSize: '14px',
        fontFamily: '"Inter", sans-serif',
      },
      colors: chartColors,
      dataLabels: {
        enabled: true,
        formatter(val) {
          return `${parseInt(val)}%`
        },
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                fontSize: '2rem',
                fontFamily: '"Inter", sans-serif',
              },
              value: {
                fontSize: '1rem',
                fontFamily: '"Inter", sans-serif',
                formatter(val) {
                  return `${parseInt(val)}%`
                },
              },
              total: {
                show: true,
                fontSize: '1.5rem',
                label: 'Scans',
                formatter() {
                  return '31%'
                },
              },
            },
          },
        },
      },

    },
  }

  const lineAreaChartTemplate = {
      series: [
        {
          name: 'Visits',
          data: [100, 120, 90, 170, 130, 160, 140, 240, 220, 180, 270, 280, 375],
        },
        {
          name: 'Clicks',
          data: [60, 80, 70, 110, 80, 100, 90, 180, 160, 140, 200, 220, 275],
        },
        {
          name: 'Sales',
          data: [20, 40, 30, 70, 40, 60, 50, 140, 120, 100, 140, 180, 220],
        },
      ],
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: false,
          curve: 'straight',
        },
        legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'left',
          fontSize: '14px',
          fontFamily: '"Inter", sans-serif',
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        xaxis: {
          categories: [
            '7/12',
            '8/12',
            '9/12',
            '10/12',
            '11/12',
            '12/12',
            '13/12',
            '14/12',
            '15/12',
            '16/12',
            '17/12',
            '18/12',
            '19/12',
            '20/12',
          ],
        },
        yaxis: {
          // opposite: isRtl
        },
        fill: {
          opacity: 1,
          type: 'solid',
        },
        tooltip: {
          shared: false,
        },
        colors: ['#e0cffe','#b992fe'],
      },
    };

  const radialBarChartTemplate = {
    series: [80, 50, 35],
    chartOptions: {
      labels: ['Comments', 'Replies', 'Shares'],
      colors: chartColors,
      plotOptions: {
        radialBar: {
          size: 185,
          hollow: {
            size: '25%',
          },
          track: {
            margin: 15,
          },
          dataLabels: {
            name: {
              fontSize: '1rem',
              fontFamily: '"Inter", sans-serif',
            },
            value: {
              fontSize: '1rem',
              fontFamily: '"Inter", sans-serif',
            },
            total: {
              show: false,
              fontSize: '1rem',
              label: 'Versand',
              formatter: function(val) {
                return radialBarChartTemplate.series[0];
              },
            },
          },
        },
      },
      legend: {
        show: true,
        position: 'bottom',
        horizontalAlign: 'center',
      },
      stroke: {
        lineCap: 'round',
      },
    },
  };

  const cutInMM = 3;
  const protectedLayerKeys = [
    'activeToggle',
    'qrcodeContentHandler',
    'dataPrivacyContentHandler',
  ];

  const formats = [
    {
      label: 'Maxipostkarte',
      value: 'maxipostkarte',
      image: require('@/assets/images/nitramit/2024-04-19-Maxipostkarte.png'),
      pdf: require('@/assets/pdfs/maxipostkarte.pdf'),
      width: 241,
      height: 131,
      disabled: false,
      hintText: "Endformat: 235 x 125 mm | Datenformat: 241 x 131 mm",
      pages: ['dateiVorderseite','dateiRueckseite'],
      layers: {
        dateiVorderseite: (options) => [
          {
            active: true,
            activeToggle: options.activeToggle ?? true,
            toggleLabel: 'QR-Code',
            qrcodeContentHandler : options.qrcodeContentHandler ?? false,
            qrcodeContentType : options.qrcodeContentType ?? 'website',
            qrcodeContent : options.qrcodeContent ?? '',
            type: 'qrcode',
            name: 'qrcode',
            requiredRequest: 'addQrcode',
            text : 'QR-Code',
            posXInMM : 3,
            posYInMM : 30,
            rotationInDeg : 0,
            widthInMM : 20,
            useMovement : true,
            useWidthAndHeightHandle : true,
          }
        ],
        dateiRueckseite: (options) => [
          {
            active: true,
            dataPrivacyContentHandler:options.dataPrivacyContentHandler ?? false,
            name: 'dataPrivacy',
            type: 'textBlock',
            text: 'Der Datenherkunftstext wird erst bei der Erstellung der Kampagne eingefügt, da er von der gewählten Zielgruppe abhängt.',
            posXInMM: 3,
            posYInMM: 3,
            rotationInDeg: 0,
            fontSizeInMM: 2,
            paddingLeftInMM: 1,
            paddingRightInMM: 1,
            paddingTopInMM: 1,
            paddingBottomInMM: 1,
            lineHeightInMM: 2,
            widthInMM: 55,
            useMovement : true,
            useFontHandle : true,
            useWidthHandle : true,
            useRotationHandle : true,
          },
          {
            active: true,
            type: 'shape',
            name: 'stampAndRecipientZone',
            posXInMM: options.dimension.width - (74 + cutInMM),
            posYInMM: 0,
            widthInMM:74 + cutInMM,
            heightInMM: options.dimension.height,
          },
          {
            active: true,
            type: 'shape',
            name: 'codingZone',
            posXInMM : options.dimension.width - (150 + cutInMM),
            posYInMM : options.dimension.height - (15 + cutInMM),
            widthInMM : 150 + cutInMM,
            heightInMM : 15 + cutInMM,
          },
          {
            active: true,
            type: 'textBlock',
            requiredRequest: 'showExampleRecipient',
            name: 'exampleRecipient',
            posXInMM: 169+cutInMM,
            posYInMM: 62+cutInMM,
            widthInMM: 64,
            fontSizeInMM: 3.88,
            lineHeightInMM: 4,
            text: options.exampleRecipient ?? 'Max Mustermann<br>Musterstr. 30<br>44879 Musterstadt',
            useTextAsHtml: true,
          },
        ]
      }
    },
    {
      label: 'DINA4-Mailing',
      value: 'dina4mailing',
      image: require('@/assets/images/nitramit/2025-01-16-DINA4-ein-seitig-blau.png'),
      pdf: require('@/assets/pdfs/dina4mailing.pdf'),
      width: 216,
      height: 303,
      disabled: false,
      hintText: "Endformat: 210 x 297mm | Datenformat: 216 x 303mm",
      pages: ['dateiVorderseite'],
      layers: {
        dateiVorderseite: (options) => [
          {
            active: true,
            activeToggle: options.activeToggle ?? true,
            toggleLabel: 'QR-Code',
            qrcodeContentHandler : options.qrcodeContentHandler ?? false,
            qrcodeContentType : options.qrcodeContentType ?? 'website',
            qrcodeContent : options.qrcodeContent ?? '',
            type: 'qrcode',
            name: 'qrcode',
            requiredRequest: 'addQrcode',
            text : 'QR-Code',
            posXInMM : 3,
            posYInMM : 30,
            rotationInDeg : 0,
            widthInMM : 20,
            useMovement : true,
            useWidthAndHeightHandle : true,
          },
          {
            active: true,
            dataPrivacyContentHandler:options.dataPrivacyContentHandler ?? false,
            name: 'dataPrivacy',
            type: 'textBlock',
            text: 'Der Datenherkunftstext wird erst bei der Erstellung der Kampagne eingefügt, da er von der gewählten Zielgruppe abhängt.',
            posXInMM: 3,
            posYInMM: 3,
            rotationInDeg: 0,
            fontSizeInMM: 2,
            paddingLeftInMM: 1,
            paddingRightInMM: 1,
            paddingTopInMM: 1,
            paddingBottomInMM: 1,
            lineHeightInMM: 2,
            widthInMM: 55,
            useMovement : true,
            useFontHandle : true,
            useWidthHandle : true,
            useRotationHandle : true,
          }
        ],
        dateiRueckseite: (options) => []
      }
    },
    {
      label: 'DINA4-Mailing',
      value: 'dina4mailing-2seitig',
      image: require('@/assets/images/nitramit/2025-01-16-DINA4-zwei-seitig-blau.png'),
      pdf: require('@/assets/pdfs/dina4mailing.pdf'),
      width: 216,
      height: 303,
      disabled: false,
      hintText: "Endformat: 210 x 297mm | Datenformat: 216 x 303mm",
      pages: ['dateiVorderseite','dateiRueckseite'],
      layers: {
        dateiVorderseite: (options) => [
          {
            active: true,
            name: 'dataPrivacy',
            type: 'textBlock',
            text: 'Der Datenherkunftstext wird erst bei der Erstellung der Kampagne eingefügt, da er von der gewählten Zielgruppe abhängt.',
            posXInMM: 3,
            posYInMM: 3,
            rotationInDeg: 0,
            fontSizeInMM: 2,
            paddingLeftInMM: 1,
            paddingRightInMM: 1,
            paddingTopInMM: 1,
            paddingBottomInMM: 1,
            lineHeightInMM: 2,
            widthInMM: 55,
            useMovement : true,
            useFontHandle : true,
            useWidthHandle : true,
            useRotationHandle : true,
          },
          {
            active: true,
            activeToggle:options.activeToggle ?? true,
            toggleLabel: 'QR-Code',
            qrcodeContentHandler : options.qrcodeContentHandler ?? false,
            qrcodeContentType : options.qrcodeContentType ?? 'website',
            qrcodeContent : options.qrcodeContent ?? '',
            name: 'qrcode',
            type: 'qrcode',
            requiredRequest: 'addQrcode',
            text : 'QR-Code',
            posXInMM : 3,
            posYInMM : 30,
            rotationInDeg : 0,
            widthInMM : 20,
            useMovement : true,
            useWidthAndHeightHandle : true,
          }
        ],
        dateiRueckseite: (options) => []
      }
    },
  ];

  const deepMergeLayers = (objA, objB)=> {
    if (Array.isArray(objA) && Array.isArray(objB)) {
      return objA.map((item, index) =>
        objB[index] ? deepMergeLayers(item, objB[index]) : item
      ).concat(objB.slice(objA.length));
    } else if (typeof objA === "object" && typeof objB === "object") {
      return Object.keys({...objA, ...objB}).reduce((acc, key) => {
        // Falls der Key geschützt ist, immer objA nehmen
        if (protectedLayerKeys.includes(key)) {
          acc[key] = objA[key];
        } else {
          acc[key] = deepMergeLayers(objA[key], objB[key]);
        }
        return acc;
      }, {});
    }
    return objB !== undefined ? objB : objA;
  }



  function mergeLayerWithOverwrite(layer, overwrite) {
    function applyOverwrite(obj, overwrite) {
      return Array.isArray(obj) ? obj.map(item => ({ ...item, ...overwrite })) : obj;
    }

    return Object.fromEntries(
      Object.entries(layer).map(([key, value]) => [key, applyOverwrite(value, overwrite)])
    );
  }

  function replaceLayerValueByName(obj, name, valueKey, newValue) {
    if (Array.isArray(obj)) {
      return obj.map(item => replaceLayerValueByName(item, name, valueKey, newValue));
    } else if (typeof obj === "object" && obj !== null) {
      return Object.keys(obj).reduce((acc, key) => {
        if (key === "name" && obj[key] === name) {
          acc = { ...obj, [valueKey]: newValue };
        } else {
          acc[key] = replaceLayerValueByName(obj[key], name, valueKey, newValue);
        }
        return acc;
      }, {});
    }
    return obj;
  }

  function getLayerValueByName(obj, name, valueKey) {
    if (Array.isArray(obj)) {
      return obj.flatMap(item => getLayerValueByName(item, name, valueKey));
    } else if (typeof obj === "object" && obj !== null) {
      if (obj.name === name && obj.hasOwnProperty(valueKey)) {
        return [obj[valueKey]];
      }
      return Object.values(obj).flatMap(value => getLayerValueByName(value, name, valueKey));
    }
    return [];
  }

  const fetchFormatTupel = (value) => {
    let tupel = {
      label: "",
      value: "",
      width: 0,
      height: 0,
      hintText: 0,
    };
    formats.forEach(item => {
      if(item.value == value){
        tupel = item;
      }
    })
    return tupel;
  }

  return {
    resolveUserStatusVariant,
    resolveUserStatusText,
    downloadCSVData,
    genders,
    purchasingPowers,
    houseTypes,
    getMatchedItem,
    convertToGermanFormat,
    campaignStates,
    getCampaignStateText,
    getCampaignStateColor,
    getCampaignStateDescription,
    getPaymentColor,
    donutTemplate,
    lineAreaChartTemplate,
    radialBarChartTemplate,
    replaceLayerValueByName,
    getLayerValueByName,
    cutInMM,
    deepMergeLayers,
    mergeLayerWithOverwrite,
    formats,
    fetchFormatTupel,
  }
}
